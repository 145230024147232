import { ChevronLeftIcon, ChevronRightIcon, PlusCircleIcon, RefreshIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { patchData } from "../backend";
import { WorkflowContext } from "../contexts";
import { BLOCK_ICON_MAP } from "../utils";
import WorkflowOverviewBlockModal from "./WorkflowOverviewBlockModal";
import useUser from "../hooks/useUser";
import SmallTextInputField from "./SmallTextInputField";
import { WorkflowWebhookConfig } from "../interfaces";
import useWebhookEvents from "../hooks/useWebhookEvents";
import OutgoingWebhookEventTableRow from "./OutgoingWebhookEventTableRow";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function WorkflowOverviewBlockWriteToSourceModal(props: Props) {
  const [isSaving, setIsSaving] = useState(false);

  const { hasPermission } = useUser();
  const { workflow, mutateWorkflow } = useContext(WorkflowContext);

  const [webhookConfig, setWebhookConfig] = useState<WorkflowWebhookConfig>(workflow.webhookConfig!);

  const [pageNumber, setPageNumber] = useState(1);

  const { data, mutate, isValidating } = useWebhookEvents(webhookConfig.id, pageNumber);
  const { results: events, numPages, count } = data;

  const [showEvents, setShowEvents] = useState(false);
  const [showHeaders, setShowHeaders] = useState(false);

  const [customRequestHeaders, setCustomRequestHeaders] = useState<Array<{ key: string, value: string }>>(webhookConfig.customRequestHeaders ?? []);

  const isValidAddress = webhookConfig.webhookUrl.length > 0 && /^https?:\/\/.*/.test(webhookConfig.webhookUrl);

  async function handleSave() {
    setIsSaving(true);

    const updatedConfig = { ...webhookConfig, customRequestHeaders };

    await patchData(`/webhooks/webhook-configs/${webhookConfig.id}/`, updatedConfig)
      .then(() => mutateWorkflow())
      .catch(console.error);

    setIsSaving(false);
    props.setOpen(false);
  }

  function updateCustomeHeader(index: number, updates: Partial<{ key: string, value: string }>) {
    setCustomRequestHeaders(prev => prev.map((header, i) => i === index ? { ...header, ...updates } : header));
  }

  return (
    <WorkflowOverviewBlockModal open={props.open} setOpen={props.setOpen} >
      <div className="relative flex flex-col w-[620px]">
        <XIcon className="absolute w-6 h-6 -top-4 -right-4 cursor-pointer text-gray-400 hover:text-gray-600" type="button" onClick={() => props.setOpen(false)} />

        <div className="flex items-center gap-2 mb-4">
          <img className="h-10 w-10" src={BLOCK_ICON_MAP["WEBHOOK"]} alt="Google Sheets Icon" />
          <div className="flex flex-col">
            <div className="font-gilroy font-semibold">
              Send Webhook Events
            </div>
            <div className="text-gray-600 text-sm">Send Document Links and Events to an External Webhook Address</div>
          </div>
        </div>

        <div className="flex flex-col gap-2 mb-4">
          <SmallTextInputField label="Webhook URL" value={webhookConfig.webhookUrl} onChange={webhookUrl => setWebhookConfig(prev => ({ ...prev, webhookUrl }))} invalid={!isValidAddress} />
        </div>

        <div className="flex items-center border-b pb-2">
          <ChevronRightIcon className="w-4 h-4 mr-1 flex-shrink-0 cursor-pointer" style={{ "rotate": showHeaders ? "90deg" : "0deg" }} onClick={() => setShowHeaders(f => !f)} />
          <div className="cursor-pointer font-semibold font-gilroy text-sm text-gray-600 select-none" onClick={() => setShowHeaders(f => !f)}>
            Custom Request Headers
          </div>
        </div>
        {showHeaders &&
          <div className="flex flex-col overflow-y-auto max-h-80 pb-4">
            {customRequestHeaders && customRequestHeaders.map(({ key, value }, index) =>
              <div key={index} className="flex items-center gap-2 w-full">
                <SmallTextInputField label="Header" className="w-1/3" value={key} onChange={key => updateCustomeHeader(index, { key })} />
                <SmallTextInputField label="Value" className="w-2/3" value={value} onChange={value => updateCustomeHeader(index, { value })} />
                <TrashIcon className="w-6 h-6 cursor-pointer text-gray-400 hover:text-red mt-1" onClick={() => setCustomRequestHeaders(prev => prev.filter((_, i) => i !== index))} />
              </div>
            )}
            <button className="btn btn-white flex items-center text-sm gap-2 px-1 py-0 w-40 mt-2" onClick={() => setCustomRequestHeaders(prev => ([...prev, { key: "", value: "" }]))}>
              <PlusCircleIcon className="w-4 h-4" /> Add Header
            </button>
          </div>
        }
        <div className="flex items-center border-b py-2">
          <ChevronRightIcon className="w-4 h-4 mr-1 flex-shrink-0 cursor-pointer" style={{ "rotate": showEvents ? "90deg" : "0deg" }} onClick={() => setShowEvents(f => !f)} />
          <div className="cursor-pointer font-semibold font-gilroy text-sm text-gray-600 select-none" onClick={() => setShowEvents(f => !f)}>
            Event History ({count})
          </div>
          {showEvents &&
            <RefreshIcon className={`h-4 w-4 ml-2 hover:text-blue cursor-pointer ${isValidating ? "animate-spin" : "animate-none"}`} onClick={() => mutate()} />
          }
          {(showEvents && count > 0) &&
            <div className="ml-auto text-sm items-center flex gap-1 select-none">
              <ChevronLeftIcon className={`w-4 h-4 flex-shrink-0 ${pageNumber === 1 ? "text-gray-400 cursor-not-allowed" : "cursor-pointer"}`} onClick={() => setPageNumber(n => Math.max(n - 1, 1))} />
              {20 * (pageNumber - 1) + 1} - {Math.min(20 * pageNumber, count)}
              <ChevronRightIcon className={`w-4 h-4 flex-shrink-0 ${pageNumber === numPages ? "text-gray-400 cursor-not-allowed" : "cursor-pointer"}`} onClick={() => setPageNumber(n => Math.min(n + 1, numPages))} />
            </div>
          }
        </div>
        {showEvents &&
          <div className="overflow-y-scroll h-[300px] border-b border-1">
            {events?.map((event) => <OutgoingWebhookEventTableRow key={event.id} event={event} />)}
            {count === 0 &&
              <div className="w-full h-full flex">
                <div className="m-auto font-semibold text-gray-400 text-sm">
                  No outgoing webhook events have been sent yet
                </div>
              </div>
            }
          </div>
        }

        <div className="flex justify-between items-center mt-4">
          <div className="text-xs text-gray-600 mr-8">
            Portant will POST events to this webhook when documents are created, or other workflow events occur. <a className="underline hover:text-blue" target="_blank" rel="noreferrer" href="https://docs.portant.co/portant-docs/developer/sending-data-to-webhooks/">See documentation</a>
          </div>
          {hasPermission(workflow.team, "workflows.edit") &&
            <button className="btn btn-blue text-sm w-48" onClick={handleSave} type="button" disabled={isSaving}>{isSaving ? "Saving..." : "Save"}</button>
          }
        </div>
      </div>
    </WorkflowOverviewBlockModal>
  );
}

export default WorkflowOverviewBlockWriteToSourceModal;
